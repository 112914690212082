/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { dataActions } from "../../../../shared/store/data-Slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CloseBlack, Warn } from "../../../common/svg/AllSvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PrimaryTextarea from "../../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import useAxios from "../../../../shared/hooks/use-axios";
import { url } from "../../../../shared/utils/url";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";

const ReferBackComplaint = () => {
    const { t } = useTranslation('common');
    const referBackComplaint: boolean = useSelector((state: any) => state.data.referBackComplaint);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [submit, setSubmit] = useState<boolean>(false)
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            activity_remarks: ''
        },
        validationSchema: yup.object().shape({
            activity_remarks: yup.string().max(512, t("grievance.refer_back_complaint.reason_len")).required(t("grievance.refer_back_complaint.reason")),
        }),
        onSubmit: async (values: any) => {
            //submit
            try {
                setSubmit(true)
                let obj: any = {
                    status: "Refer_To_Customer",
                    activity_remarks: values?.activity_remarks
                }
                const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.refer_back_compaint + params?.id}/`, obj);

                if (res && res.status === 200) {
                    dispatch(dataActions.setComplaintApiCall(true));
                    dispatch(dataActions.setReferBackComplaint(false));
                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                }
            } catch (error) {
                errors(error)
                setSubmit(false)
                console.error("error while status change", error);
            }
        }
    });

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setReferBackComplaint(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setReferBackComplaint(false))
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    return (
        <>
            {referBackComplaint &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[665px] h-auto relative bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex items-center justify-between gap-[15px] border-b border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText font-semibold">{t("grievance.refer_back_complaint.title")}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" onClick={() => {
                                    dispatch(dataActions.setReferBackComplaint(false))
                                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="py-[16px] px-[22px] flex flex-col gap-[25px] xlx:gap-[30px]">
                                <div className="flex flex-col gap-[4px]">
                                    <PrimaryTextarea title={`${t("grievance.refer_back_complaint.add_reason")}*`} placeholder={t("contact_us.form.start_typing")} name="activity_remarks" rows="4" value={formik?.values?.activity_remarks} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoFocus={true} />
                                    {formik?.touched?.activity_remarks && formik?.errors?.activity_remarks && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger text-xs font-medium">{formik?.errors?.activity_remarks}</span>
                                    </div>}
                                </div>

                                <div className="flex w-full items-end justify-end">
                                    <SecondaryBtn title={t("contact_us.form.submit")} disabled={submit} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default ReferBackComplaint;