/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, Bounce } from "react-toastify";
import { dataActions } from "../../../shared/store/data-Slice";
import { useEffect, useRef, useState } from "react";
import { CloseBtn, ProfileSuccessIcon, SecondaryArr, Warn } from "../../common/svg/AllSvg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SelectSecondaryComponent from "../../common/form/SelectSecondaryComponent";
import { components } from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { errors } from "../../../shared/utils/errors";
import PrimaryTextarea from "../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../common/form/SecondaryBtn";
import PrimaryInput from "../../common/form/PrimaryInput";
import { numberOnPaste } from "../../../shared/utils/numberOnPaste";

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <SecondaryArr />
            </components.DropdownIndicator>
        )
    );
};

const RatingComponent = ({
    rating,
    handleClick,
    theme
}: any) => {
    return (
        <div style={{ display: "flex", gap: "5px", cursor: "pointer" }}>
            {Array.from({ length: 5 }, (_, index) => {
                const starIndex = index + 1;
                return (
                    <button className="focus:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] hover:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] rounded-md" type="button" key={index} onClick={() => handleClick(starIndex)}>
                        <svg
                            key={starIndex}
                            width={40}
                            height={40}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                fill:
                                    starIndex <= (rating) ? "#FCAB02" : theme === "light" ? "#4e566d66" : "#4e566d",
                                transition: "fill 0.2s ease-in-out",
                            }}
                        >
                            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                        </svg>
                    </button>
                );
            })}
        </div>
    );
};

const Feedback = () => {
    const { t } = useTranslation('common');
    const feedbackOpen: boolean = useSelector((state: any) => state.data.feedbackOpen);
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const theme: any = useSelector((state: any) => state.ui.theme);
    const [submit, setSubmit] = useState<boolean>(false)
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [rating, setRating] = useState(0);
    const [rating1, setRating1] = useState(0);
    const [rating2, setRating2] = useState(0);
    const [rating3, setRating3] = useState(0);
    const [rating4, setRating4] = useState(0);
    const [category, setCategory] = useState<any>(null);
    const [sts, setSts] = useState<any[]>([]);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const ref: any = useRef();

    const categoryList: any[] = [
        {
            "label": t("passenger.home.feedback.category_list.appreciation"),
            "value": "appreciation",
        },
        {
            "label": t("passenger.home.feedback.category_list.suggestion"),
            "value": "suggestion",
        },
        {
            "label": t("passenger.home.feedback.category_list.request"),
            "value": "request",
        },
        {
            "label": t("passenger.home.feedback.category_list.enquiry"),
            "value": "enquiry",
        },
        {
            "label": t("passenger.home.feedback.category_list.cleanliness"),
            "value": "cleanliness",
        }
    ]

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: !!user?.first_name ? user?.first_name : '',
            last_name: !!user?.last_name ? user?.last_name : '',
            email: !!user?.email ? user?.email : '',
            phone: !!user?.phone ? user?.phone : '',
            category: '',
            message: '',
            rating_value: '',
            station: '',
            cleanliness_st: '',
            cleanliness_train: '',
            cleanliness_lift: '',
            washroom_st: '',
            cleanliness_suggestions: ''
        },
        validationSchema: category?.value === "cleanliness" ?
            yup.object().shape({
                first_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.first_name")),
                last_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.last_name")),
                email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
                phone: yup.number().test('len', t("contact_us.form.wrong_contact_number_err"), (val: any) => val && val.toString().length === 10).required(t("contact_us.form.contact_number_err")),
                category: yup.object().required(t("passenger.home.feedback.form.validation.category")),
                message: yup.string().required(t("passenger.home.feedback.form.validation.message")),
                station: yup.object().required(t("passenger.home.feedback.form.validation.station")),
                cleanliness_st: yup.number().required(t("passenger.home.feedback.form.validation.cleanliness_st")),
                cleanliness_train: yup.number().required(t("passenger.home.feedback.form.validation.cleanliness_train")),
                cleanliness_lift: yup.number().required(t("passenger.home.feedback.form.validation.cleanliness_lift")),
                washroom_st: yup.number().required(t("passenger.home.feedback.form.validation.washroom_st")),
                cleanliness_suggestions: yup.string().required(t("passenger.home.feedback.form.validation.cleanliness_suggestions")),
            })
            :
            yup.object().shape({
                first_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.first_name")),
                last_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.last_name")),
                email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
                phone: yup.number().test('len', t("contact_us.form.wrong_contact_number_err"), (val: any) => val && val.toString().length === 10).required(t("contact_us.form.contact_number_err")),
                category: yup.object().required(t("passenger.home.feedback.form.validation.category")),
                message: yup.string().required(t("passenger.home.feedback.form.validation.message")),
                rating_value: yup.number().required(t("passenger.home.feedback.form.validation.rating")),
            }),
        onSubmit: async (values: any) => {
            //submit
            try {
                setSubmit(true)
                let obj: any = category?.value === "cleanliness" ?
                    {
                        first_name: values?.first_name,
                        last_name: values?.last_name,
                        contact_number: values?.phone,
                        email: values?.email,
                        message: values?.message,
                        category: values?.category?.value,
                        cleaning_survey_station: values?.station?.value,
                        cleanliness_of_station: values?.cleanliness_st,
                        cleanliness_of_train: values?.cleanliness_train,
                        cleanliness_of_lift: values?.cleanliness_lift,
                        washroom_facilities_at_station: values?.washroom_st,
                        suggestions_for_improving: values?.cleanliness_suggestions
                    }
                    : {
                        first_name: values?.first_name,
                        last_name: values?.last_name,
                        contact_number: values?.phone,
                        email: values?.email,
                        rating: values?.rating_value,
                        message: values?.message,
                        category: values?.category?.value,
                    }
                const res: any = await useAxios.post(`${url?.add_feedback}`, obj);

                if (res && res.status === 201) {
                    setModalStatus(true)
                    setSubmit(false)
                }
            } catch (error) {
                errors(error)
                setSubmit(false)
                console.error("error while post feedback", error);
            }
        }
    });

    useEffect(() => {
        const getExistUser = async () => {
            try {
                const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.get_exist_user}`, { mobile: formik?.values?.phone });

                if (res && res.status === 200) {
                    !!res?.data?.first_name && formik.setFieldValue("first_name", res?.data?.first_name)
                    !!res?.data?.last_name && formik.setFieldValue("last_name", res?.data?.last_name)
                    !!res?.data?.email && formik.setFieldValue("email", res?.data?.email)
                }
            } catch (error) {
                console.error("error while fetching user details", error);
            }
        }

        if (formik?.values?.phone?.toString()?.length === 10) {
            getExistUser()
        }
    }, [formik?.values?.phone])

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFeedbackOpen(false))
            setModalStatus(false)
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setFeedbackOpen(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        if (!!lang && !!formik.values.category) {
            let obj: any = categoryList?.find((item: any) => item?.value === formik.values.category?.value)
            !!obj && formik.setFieldValue("category", obj)
        }
    }, [lang])

    const handleClick = (index: any) => {
        setRating(index);
        if (index === 0) {
            formik.setFieldValue("rating_value", '')
        } else {
            formik.setFieldValue("rating_value", index)
        }
    };

    const handleClick1 = (index: any) => {
        setRating1(index);
        if (index === 0) {
            formik.setFieldValue("cleanliness_st", '')
        } else {
            formik.setFieldValue("cleanliness_st", index)
        }
    };

    const handleClick2 = (index: any) => {
        setRating2(index);
        if (index === 0) {
            formik.setFieldValue("cleanliness_train", '')
        } else {
            formik.setFieldValue("cleanliness_train", index)
        }
    };

    const handleClick3 = (index: any) => {
        setRating3(index);
        if (index === 0) {
            formik.setFieldValue("cleanliness_lift", '')
        } else {
            formik.setFieldValue("cleanliness_lift", index)
        }
    };

    const handleClick4 = (index: any) => {
        setRating4(index);
        if (index === 0) {
            formik.setFieldValue("washroom_st", '')
        } else {
            formik.setFieldValue("washroom_st", index)
        }
    };

    const getStations = async () => {
        try {
            let apiUrl: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}all/`);

            if (!!apiUrl) {
                const res: any = apiUrl;
                if (res && res?.status === 200 && res?.data?.length > 0) {
                    const arr: any[] = Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.name, value: item?.id }));
                    setSts(arr)

                    if (!!formik?.values?.station) {
                        let obj: any = res?.data?.find((item: any) => formik?.values?.station?.value === item?.id)
                        formik.setFieldValue("station", obj)
                    }
                }
            }

        } catch (error) {
            console.error("error while feching stations", error);
        }
    }

    useEffect(() => {
        !!lang && getStations();
    }, [lang])

    return (
        <>
            {feedbackOpen &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[550px] xlx:w-[600px] h-full relative dark:bg-bgDarkDull bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex justify-between gap-[15px] px-[20px] xlx:px-[30px] py-[15px] xlx:py-[20px]">
                                <div className="flex flex-col xlx:gap-[2px]">
                                    <h3 className="text-primaryText dark:text-white font-semibold">{t("passenger.home.feedback.title")}</h3>
                                    <p className="text-blackDark dark:text-textBlackDark font-semibold">{t("passenger.home.feedback.desc")}</p>
                                </div>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => { dispatch(dataActions.setFeedbackOpen(false)); !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`) }}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="py-[30px] pt-0 px-[22px] flex flex-col gap-[25px]">
                                {category?.value !== "cleanliness" && <div className="flex flex-col gap-[4px]">
                                    <RatingComponent
                                        rating={rating}
                                        handleClick={handleClick}
                                        theme={theme}
                                    />
                                    {formik?.touched?.rating_value && formik?.errors?.rating_value && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.rating_value}</span>
                                    </div>}
                                </div>}

                                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryInput title={`${t("auth.form.field.first_name")}*`} name="first_name" type="text" placeholder={t("auth.form.field.first_name")} value={formik?.values?.first_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.first_name && formik?.errors?.first_name && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.first_name}</span>
                                        </div>}
                                    </div>

                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryInput title={`${t("auth.form.field.last_name")}*`} name="last_name" type="text" placeholder={t("auth.form.field.last_name")} value={formik?.values?.last_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.last_name && formik?.errors?.last_name && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.last_name}</span>
                                        </div>}
                                    </div>
                                </div>

                                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                    <div className="flex flex-col gap-[4px]">
                                        <h6 className="text-blackDark font-medium">{t("grievance.new_complaint.field.phone")}*</h6>
                                        <div className="flex items-center gap-[10px] w-full">
                                            <input className="flex items-center justify-center px-[7px] w-[35px] xlx:w-[40px] h-[35px] xlx:h-[40px] rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] text-blackDull text-sm leading-snug" defaultValue="+91" disabled autoComplete="off" />
                                            <input className="placeholder:text-blackDull w-full rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug" type="number" name="phone" placeholder={t("grievance.new_complaint.field.phone")} autoComplete="off" value={formik?.values?.phone} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                                                onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onPaste={(e: any) => numberOnPaste(e)} />
                                        </div>
                                        {formik?.touched?.phone && formik?.errors?.phone && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{formik?.errors?.phone}</span>
                                        </div>}
                                    </div>

                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryInput title={`${t("auth.form.field.email")}*`} name="email" type="email" placeholder={t("auth.form.field.email")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.email}</span>
                                        </div>}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-[4px]">
                                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.category")}*</h6>
                                    <SelectSecondaryComponent
                                        options={categoryList}
                                        name="category"
                                        placeholder={t("passenger.home.feedback.form.placeholder.category")}
                                        isSearchable={false}
                                        arrow={true}
                                        components={{ DropdownIndicator }}
                                        onChange={(e: any) => { formik.setFieldValue("category", e); setCategory(e); }}
                                        onBlur={(e: any) => formik.setFieldTouched("category", e)}
                                        value={formik.values.category}
                                    />
                                    {formik?.touched?.category && formik?.errors?.category && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.category}</span>
                                    </div>}
                                </div>

                                {category?.value === "cleanliness" && <>
                                    <div className="flex flex-col gap-[4px]">
                                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.station")}*</h6>
                                        <SelectSecondaryComponent
                                            options={sts}
                                            name="station"
                                            placeholder={t("passenger.home.feedback.form.placeholder.station")}
                                            isSearchable={false}
                                            arrow={true}
                                            components={{ DropdownIndicator }}
                                            onChange={(e: any) => formik.setFieldValue("station", e)}
                                            onBlur={(e: any) => formik.setFieldTouched("station", e)}
                                            value={formik.values.station}
                                        />
                                        {formik?.touched?.station && formik?.errors?.station && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.station}</span>
                                        </div>}
                                    </div>

                                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.cleanliness_st")}*</h6>
                                            <RatingComponent
                                                rating={rating1}
                                                handleClick={handleClick1}
                                                theme={theme}
                                            />
                                            {formik?.touched?.cleanliness_st && formik?.errors?.cleanliness_st && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.cleanliness_st}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.cleanliness_train")}*</h6>
                                            <RatingComponent
                                                rating={rating2}
                                                handleClick={handleClick2}
                                                theme={theme}
                                            />
                                            {formik?.touched?.cleanliness_train && formik?.errors?.cleanliness_train && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.cleanliness_train}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.cleanliness_lift")}*</h6>
                                            <RatingComponent
                                                rating={rating3}
                                                handleClick={handleClick3}
                                                theme={theme}
                                            />
                                            {formik?.touched?.cleanliness_lift && formik?.errors?.cleanliness_lift && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.cleanliness_lift}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.home.feedback.form.field.washroom_st")}*</h6>
                                            <RatingComponent
                                                rating={rating4}
                                                handleClick={handleClick4}
                                                theme={theme}
                                            />
                                            {formik?.touched?.washroom_st && formik?.errors?.washroom_st && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.washroom_st}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryTextarea title={`${t("passenger.home.feedback.form.field.cleanliness_suggestions")}*`} placeholder={t("passenger.home.feedback.form.placeholder.message")} name="cleanliness_suggestions" rows="4" value={formik?.values?.cleanliness_suggestions} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.cleanliness_suggestions && formik?.errors?.cleanliness_suggestions && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.cleanliness_suggestions}</span>
                                        </div>}
                                    </div>
                                </>}

                                <div className="flex flex-col gap-[4px]">
                                    <PrimaryTextarea title={`${t("passenger.home.feedback.form.field.message")}*`} placeholder={t("passenger.home.feedback.form.placeholder.message")} name="message" rows="4" value={formik?.values?.message} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                    {formik?.touched?.message && formik?.errors?.message && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.message}</span>
                                    </div>}
                                </div>

                                <div className="flex w-full items-end justify-end">
                                    <SecondaryBtn title={t("contact_us.form.submit")} disabled={submit} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}

            {/* Success Modal */}
            {modalStatus &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => {
                            setModalStatus(false); dispatch(dataActions.setFeedbackOpen(false)); !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                        }}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto dark:bg-bgDarkDull bg-white rounded-[10px] w-full md:w-[560px] h-[450px] xlx:h-[528px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="absolute top-[18px] right-[22px]">
                                <button type="button" aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" autoFocus={true} onClick={() => {
                                    setModalStatus(false); dispatch(dataActions.setFeedbackOpen(false)); !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                }}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-center px-[22px]">
                                <ProfileSuccessIcon />
                                <h3 className="text-primaryText dark:text-white font-bold mt-[20px] xlx:mt-[30px] text-center">{t("passenger.home.feedback.success.title")}</h3>
                                <h5 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[15px] xlx:mt-[18px]">{t("passenger.home.feedback.success.desc")}</h5>

                                <div className="mt-[50px]">
                                    <SecondaryBtn title={t("aria_label.home")} onClick={() => {
                                        dispatch(dataActions.setFeedbackOpen(false));
                                        setModalStatus(false);
                                        !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                    }} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Feedback;